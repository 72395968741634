import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import './Product.css';
import { useCart } from '../contexts/CartContext';

function Product() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [relatedProducts, setRelatedProducts] = useState([]);
  const navigate = useNavigate();
  const { dispatch } = useCart();
  const [quantity, setQuantity] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [showStockNotification, setShowStockNotification] = useState(false);
  const [email, setEmail] = useState(''); // New state for email
  const [emailSubmitted, setEmailSubmitted] = useState(false); // New state to track submission

  useEffect(() => {
    const fetchProduct = async () => {
      const productDoc = await getDoc(doc(db, 'products', id));
      if (productDoc.exists()) {
        setProduct(productDoc.data());
        setMainImage(productDoc.data().mainImage);
      }
    };

    fetchProduct();
  }, [id]);

  const handleImageClick = (image) => {
    setMainImage(image); // Handle image switching
  };

  const handleEmailSubmit = async () => {
    if (email) {
      try {
        await addDoc(collection(db, 'Esgotados'), {
          email: email,
          product: product.name,
          productId: id,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
        });
        setEmailSubmitted(true);
        alert('You will be notified when the product is back in stock!');
      } catch (error) {
        console.error('Error adding email:', error);
      }
    } else {
      alert('Insere o teu email para nós conseguirmos avisar');
    }
  };

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  const handleAddToCart = () => {
    if (quantity > product.stock) {
      setShowStockNotification(true);
      setTimeout(() => {
        setShowStockNotification(false);
      }, 3000);
    } else {
      const productWithCode = { ...product, id: product.code, quantity };
      dispatch({ type: 'ADD_TO_CART', product: productWithCode });
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  };

  if (!product) return <div>Loading...</div>;

  const discountPrice = product.price - (product.price * product.discount / 100);

  return (
    <div className='pagfin'>
      <div className="product-detail">
        <div className="image-gallery">
          <div className="thumbnail-container">
            <img
              className="thumbnail"
              src={product.mainImage}
              alt={product.name}
              onClick={() => handleImageClick(product.mainImage)} // Use the handleImageClick function
            />
            <img
              className="thumbnail"
              src={product.secondaryImage}
              alt={product.name}
              onClick={() => handleImageClick(product.secondaryImage)} // Use the handleImageClick function
            />
          </div>
          <div className="main-image-container">
            <img className="main-image" src={mainImage} alt={product.name} />
          </div>
        </div>
        <div className="product-info">
          <h1>{product.name}</h1>
          <p>{product.description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}</p>
          {product.discount > 0 ? (
            <>
              <p className="price">
                <span className="original-price">{product.price}€</span> {discountPrice}€
              </p>
              <p className="discount">{product.discount}% off</p>
            </>
          ) : (
            <p className="price">{product.price}€</p>
          )}
          {product.stock > 0 ? (
            <div className="quantity-cart-container">
              <div className="quantity-container">
                <button onClick={() => handleQuantityChange(-1)}>-</button>
                <input className="b2" type="text" value={quantity} readOnly />
                <button onClick={() => handleQuantityChange(1)}>+</button>
              </div>
              <button onClick={handleAddToCart} className="add-to-cart">Adicionar ao Carrinho</button>
            </div>
          ) : (
            <>
              <p className="out-of-stock-message">Neste momento este Produto não está disponível</p>
              <p className="out-of-stock-message">Mas não te preocupes, nós avisamos quando estiver</p>

              {!emailSubmitted ? (
                <div className="email-notification">
                  <input
                    type="email"
                    placeholder="Insere o teu email para nós conseguirmos avisar"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button onClick={handleEmailSubmit}>enviar</button>
                </div>
              ) : (
                <p>Email Enviado. fica atento às novidades!</p>
              )}
            </>
          )}
        </div>
      </div>

      {showNotification && (
        <div className="popup-notification">
          <p>Produto adicionado ao carrinho com sucesso!</p>
        </div>
      )}

      {showStockNotification && (
        <div className="popup-notification">
          <p>Quantidade indisponível em estoque. Tente comprar menos.</p>
        </div>
      )}
    </div>
  );
}

export default Product;
