import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import './Shop.css';
import { useCart } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import placeholderImg from '../assets/placeholder-image.png';  // Placeholder image for when images are loading

function Shop() {
  const [products, setProducts] = useState([]);
  const [minPrice, setMinPrice] = useState(2); 
  const [maxPrice, setMaxPrice] = useState(25); 
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCollection, setSelectedCollection] = useState('');
  const [collections, setCollections] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false); 
  const { dispatch } = useCart();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const productCollection = await getDocs(collection(db, 'products'));
      const productsData = productCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsData);
      setFilteredProducts(productsData); 
    };

    const fetchCollections = async () => {
      const collectionCollection = await getDocs(collection(db, 'collections'));
      setCollections(collectionCollection.docs.map(doc => doc.data()));
    };

    fetchProducts();
    fetchCollections();
  }, []);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        await addDoc(collection(db, 'newsletter'), { email });
        alert('Subscribed successfully!');
        setEmail('');
      } catch (error) {
        console.error('Error adding email to newsletter: ', error);
      }
    }
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(parseFloat(event.target.value));
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(parseFloat(event.target.value));
  };

  const handleFilterApply = () => {
    const filtered = products.filter(product => {
      return (
        product.price >= minPrice &&
        product.price <= maxPrice &&
        (selectedCategory === '' || product.type === selectedCategory) &&
        (selectedCollection === '' || product.collection === selectedCollection)
      );
    });
    setFilteredProducts(filtered);
  };

  const handleResetFilter = () => {
    setMinPrice(2); 
    setMaxPrice(25); 
    setSelectedCategory('');
    setSelectedCollection('');
    setFilteredProducts(products); 
  };

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  const activeFilters = () => {
    let filters = [];
    if (selectedCategory) filters.push(selectedCategory);
    if (maxPrice < 35) filters.push(`até ${maxPrice}€`);
    return filters.join(', ');
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible); 
  };

  return (
    <div className="category-page">
      <Helmet>
        <title>Veja os nossos novos produtos!!</title>
        <meta name="description" content="Selecione os produtos que a Conta&Meia tem neste momento para oferecer antes que esgotem" />
        <meta name="keywords" content="jewelry, comprar,coleções, Conta&Meia, loja, shop , contaemeia, bijutaria, contacto, pulseiras , brincos, aneis, colares" />
        <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
        <meta property="og:description" content="Discover handcrafted jewelry pieces in our New Beginnings collection." />
        <meta property="og:image" content="https://https://contaemeia.com/favicon.ico" />
      </Helmet>
      <div className="div">
        <div className="filter-toggle-icon" onClick={toggleFilterVisibility}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M3 4h18v2H3zm0 7h12v2H3zm0 7h6v2H3z"/>
          </svg>
          <span>Filtros</span>
        </div>

        <div className={`frame-9 ${isFilterVisible ? 'show-filters' : ''}`}>
          <div className="frame-10">
            <div className="text-wrapper-6">Filtros</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
              <g fill="none" stroke="currentColor" stroke-linecap="round">
                <path d="M5 12V4m14 16v-3M5 20v-4m14-3V4m-7 3V4m0 16v-9"/>
                <circle cx="5" cy="14" r="2"/>
                <circle cx="12" cy="9" r="2"/>
                <circle cx="19" cy="15" r="2"/>
              </g>
            </svg>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="200" height="10" viewBox="0 0 200 2">
            <path fill="currentColor" fill-rule="evenodd" d="M0 1h200v1H0z" opacity="0.1"/>
          </svg>

          <div className="frame-11">
            <div className="frame-10 clickable" onClick={() => setSelectedCategory('colar')}>
              <div className="text-wrapper-2">Colares</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m10 17l5-5l-5-5"/>
              </svg>
            </div>
            <div className="frame-10 clickable" onClick={() => setSelectedCategory('brinco')}>
              <div className="text-wrapper-2">Brincos</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m10 17l5-5l-5-5"/>
              </svg>
            </div>
            <div className="frame-10 clickable" onClick={() => setSelectedCategory('anel')}>
              <div className="text-wrapper-2">Aneis</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m10 17l5-5l-5-5"/>
              </svg>
            </div>
            <div className="frame-10 clickable" onClick={() => setSelectedCategory('pulseira')}>
              <div className="text-wrapper-2">Pulseiras</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m10 17l5-5l-5-5"/>
              </svg>
            </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="200" height="10" viewBox="0 0 200 2">
            <path fill="currentColor" fill-rule="evenodd" d="M0 1h200v1H0z" opacity="0.1"/>
          </svg>

          <div className="frame-11">
            <div className="frame-10">
              <div className="text-wrapper-6">Preços</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m10 17l5-5l-5-5"/>
              </svg>
            </div>
            <div className="group">
              <div className="filter-price-container">
                <div className="price-range-inputs">
                  <div className="input-group">
                    <label htmlFor="minPrice">Mínimo:</label>
                    <input
                      type="number"
                      id="minPrice"
                      value={minPrice}
                      onChange={handleMinPriceChange}
                      min="0"
                      max="100"
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="maxPrice">Máximo:</label>
                    <input
                      type="number"
                      id="maxPrice"
                      value={maxPrice}
                      onChange={handleMaxPriceChange}
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bot">
            <div className="div-wrapper clickable" onClick={handleFilterApply}>
              <div className="text-wrapper-9">Aplicar Filtro</div>
            </div>
            <div className="div-wrapper clickable" onClick={handleResetFilter}>
              <div className="text-wrapper-9">Limpar Filtros</div>
            </div>
          </div>
        </div>

        <div className="text-wrapper-10">
          {activeFilters() && <p>Filtros ativos: {activeFilters()}</p>}
          <div className="products">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => {
                const discountPrice = product.price - (product.price * product.discount / 100);
                return (
                  <div key={product.id} className="group-3 clickable" onClick={() => handleProductClick(product.id)}>
                    <div className="overlap-group-2">
                      <div className="group-4">
                        <div className="image-wrapper">
                          {/* Use a placeholder image while the actual image loads */}
                          <img
                            className="image"
                            alt={product.name}
                            src={product.mainImage}
                            loading="lazy" /* Lazy loading */
                            onError={(e) => { e.target.src = placeholderImg }} /* Fallback to placeholder on error */
                          />
                        </div>
                        <div className="text-wrapper-11">{product.name}</div>
<div className="frame-12">
  {product.stock == 0 ? (
    <div className="text-wrapper-13">Esgotado</div>
  ) : (
  
    product.discount > 0 ? (
      <div className="price-container">
        <div className="text-wrapper-12 original-price">{product.price}€</div>
        <div className="text-wrapper-12 discount-price">{discountPrice}€</div>
        <div className="discounttext">{product.discount}% off</div>
      </div>
    ) : (
      <div className="text-wrapper-12">{product.price}€</div>
    )
  )}
</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No products found matching your criteria.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
