// src/pages/admin/AdminLayout.js
import React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'; // Ensure the correct import path for firebase configuration
import './AdminLayout.css';
import { Helmet } from 'react-helmet';


const AdminLayout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/c0n1aemeia-vamosficarricos-admin/login');
    });
  };

  return (
    <div className="admin-layout">
        <Helmet>
        <title>Admin</title>
        <meta name="description" content="Página de administração" />
           <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
      </Helmet>
      <nav>
        <Link to="/c0n1aemeia-vamosficarricos-admin/dashboard">Dashboard</Link>
        <Link to="/c0n1aemeia-vamosficarricos-admin/orders">Encomendas</Link>
        <Link to="/c0n1aemeia-vamosficarricos-admin/products">Produtos</Link>
        <Link to="/c0n1aemeia-vamosficarricos-admin/collections">Criar coleções</Link>
        <Link to="/c0n1aemeia-vamosficarricos-admin/admincontact">Contactos</Link>
        <Link to="/c0n1aemeia-vamosficarricos-admin/esgotados">Esgotados</Link>
        <button className="logout" onClick={handleLogout}>Logout</button>
      </nav>
      <div className="admin-content">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
