import React, { useState, useEffect } from 'react';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust import based on your file structure
import './Esgotados.css';

function Esgotados() {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      const q = query(collection(db, 'Esgotados'), orderBy('date', 'desc'), orderBy('time', 'desc'));
      const querySnapshot = await getDocs(q);
      setRequests(querySnapshot.docs.map(doc => doc.data()));
    };

    fetchRequests();
  }, []);

  return (
    <div className="esgotados-page">
      <h1>Esgotados Requests</h1>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Product</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request, index) => (
            <tr key={index}>
              <td>{request.email}</td>
              <td>{request.product}</td>
              <td>{request.date}</td>
              <td>{request.time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Esgotados;
