// src/pages/admin/AdminCollections.js
import React from 'react';
import { Link } from 'react-router-dom';
import './AdminCollections.css';
import { Helmet } from 'react-helmet';


function AdminCollections() {
  return (
    <div className="admin-collections">
        <Helmet>
        <title>Admin</title>
        <meta name="description" content="Página de administração" />
           <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
      </Helmet>
      <h1>Collections</h1>
      <Link to="/c0n1aemeia-vamosficarricos-admin/create-collection">
        <button className="create-collection-button">Create New Collection</button>
      </Link>
      {/* Existing collection management code */}
    </div>
  );
}

export default AdminCollections;
