// src/pages/admin/Login.js
import React, { useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { Helmet } from 'react-helmet';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/c0n1aemeia-vamosficarricos-admin/dashboard');
    } catch (error) {
      console.error('Error signing in: ', error);
    }
  };

  return (
    <div className="login">
        <Helmet>
        <title>Admin</title>
        <meta name="description" content="Página de administração" />
           <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
      </Helmet>
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          placeholder="Inserir Username" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
        <input 
          type="password" 
          placeholder="Inserir Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
