import React from 'react';
import './SharedStyles.css';

const PagDevolucoes = () => {
  return (
    <div className="page-container">
      <h1>Política de Devolução e Reembolso</h1>
      <p>Ao aceder e usar nosso website, concorda com a seguinte Política de Devolução e Reembolso:</p>
      <p>- O cliente tem o direito de cancelar a encomenda até 24 horas após o pagamento, desde que a encomenda ainda não tenha sido enviada.
       </p>
        <p>        - Em caso de cancelamento, entraremos em contacto no prazo de 48h para proceder ao reembolso do valor total da encomenda.
        </p>
        <p>        - O cliente é responsável pelos custos de envio para devoluções, salvo se o produto for defeituoso ou incorreto.
        </p>
        <p>      -   Em caso de defeito comprovado no produto, o cliente deve entrar em contacto connosco num prazo máximo de 14 dias após a receção do artigo para agendar a devolução ou troca do produto. O cliente deve fornecer fotos e uma descrição do defeito.
        </p>
        <p>      -   Após receber as informações requisitadas e inspecionar o item a devolver, notificaremos o cliente sobre a aprovação ou rejeição do reembolso, no prazo de 48h após o pedido de reembolso.
        </p>
        <p>      -   Substituímos ou reembolsamos o valor total da encomenda, exceto custos de envio, de itens apenas com defeito ou danificados.
        </p>
        <p>      -   Itens em promoção ou personalizados não são elegíveis para devolução.
        </p>
        <p>      -   Caso o pedido de encomenda seja recusado, por justa causa, entraremos em contacto no prazo de 48h para proceder ao reembolso do valor encomendado.
        </p>
        <p>       -  Para dúvidas e esclarecimentos, entre em contato conosco através de help.contaemeia@gmail.com.</p>
        
       
    </div>
  );
};

export default PagDevolucoes;
