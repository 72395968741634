import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const ProtectedRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div>Loading, please wait...</div>; // Add a more detailed loading message or a spinner
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Display an error message if Firebase authentication fails
  }

  if (!user) {
    return <Navigate to="/c0n1aemeia-vamosficarricos-admin/login" />;
  }

  return children;
};

export default ProtectedRoute;
