// src/App.js
import React , { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Shop from './pages/Shop';
import AboutUs from './pages/AboutUs';
import Product from './pages/Product';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Cart from './pages/Cart';
import { CartProvider } from './contexts/CartContext';
import ShippingInfo from './pages/ShippingInfo';
import PaymentInfo from './pages/PaymentInfo';
import ThankYou from './pages/ThankYou';
import Privacidade from './pages/Pag-Privacidade';
import Devolucoes from './pages/Pag-Devolucoes';
import Envios from './pages/Pag-Envios';
import Termos from './pages/Pag-Termos';

import Esgotados from './pages/admin/Esgotados';

import Sustainability from './pages/Sustainability';
import AdminLayout from './pages/admin/AdminLayout';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminOrders from './pages/admin/AdminOrders';
import AdminCont from './pages/admin/ContactList';
import AdminContDet from './pages/admin/ContactDetail';

import AdminProducts from './pages/admin/AdminProducts';
import AdminNewProduct from './pages/admin/AdminNewProduct';
import AdminEditProduct from './pages/admin/AdminEditProduct';
import AdminCollections from './pages/admin/AdminCollections';
import AdminCreateCollections from './pages/admin/AdminCreateCollection';
import OrderDetails from './pages/admin/OrderDetails';
import Login from './pages/admin/Login';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout'; // Import MainLayout
import './App.css';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component

function App() {

  useEffect(() => {
    // Function to remove all URL parameters
    if (window.location.search.length > 0) {
      const url = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, url);
    }
  }, []); // Run once when the component is mounted

  return (
    <CartProvider>
    <Router>
    <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="shop" element={<Shop />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="Politica-Privacidade" element={<Privacidade />} />
          <Route path="Politica-Devoluções" element={<Devolucoes />} />
          <Route path="Detalhes-Envios" element={<Envios />} />
          <Route path="Termos-e-Condições" element={<Termos />} />
          <Route path="cart" element={<Cart />} />
          <Route path="shipping" element={<ShippingInfo />} />
          <Route path="payment" element={<PaymentInfo />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path="sustainability" element={<Sustainability />} />
          <Route path="product/:id" element={<Product />} />
        </Route>
        <Route path="/c0n1aemeia-vamosficarricos-admin/login" element={<Login />} />
        <Route path="/c0n1aemeia-vamosficarricos-admin" element={<ProtectedRoute><AdminLayout /></ProtectedRoute>}>
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="orders" element={<AdminOrders />} />
          <Route path="ordersdetail/:orderId" element={<OrderDetails />} />
          <Route path="products" element={<AdminProducts />} />
          <Route path="admincontact" element={<AdminCont />} />
          <Route path="contactdetail/:contactId" element={<AdminContDet />} />
          <Route path="Esgotados" element={<Esgotados />} />
          <Route path="products/new" element={<AdminNewProduct />} />
          <Route path="products/edit/:id" element={<AdminEditProduct />} />
          <Route path="collections" element={<AdminCollections />} />
          <Route path="create-collection" element={<AdminCreateCollections />} />
          

        </Route>
      </Routes>
    </Router>
    </CartProvider>
  );
}

export default App;
