import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

// Import FontAwesome components and TikTok icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-columns">
        <div className="footer-column">
          <h3>Conta&Meia</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/Conta.E.Meiaa/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: 'black' }} />
            </a>
            <a href="https://www.instagram.com/conta.e.meia/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: 'black' }} />
            </a>
            <a href="https://vm.tiktok.com/ZIJWNMqVa/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} size="2x" style={{ color: 'black' }} />
            </a>
          </div>
          <p>© 2023-2024, All Rights Reserved</p>
        </div>
        <div className="footer-column">
          <h3>Empresa</h3>
          <ul>
            <li><Link to="/about-us">Sobre Nós</Link></li>
            <li><Link to="/sustainability">Sustentabilidade</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Produtos</h3>
          <ul>
            <li><Link to="/shop">Anéis</Link></li>
            <li><Link to="/shop">Pulseiras</Link></li>
            <li><Link to="/shop">Colares</Link></li>
            <li><Link to="/shop">Brincos</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Ajuda</h3>
          <ul>
            <li><Link to="/Termos-e-Condições">Termos e Condições</Link></li>
            <li><Link to="/Politica-Privacidade">Política de Privacidade</Link></li>
            <li><Link to="/Politica-Devoluções">Política de Devoluções</Link></li>
            <li><Link to="/Detalhes-Envios">Detalhes de Envio</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
