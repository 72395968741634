import React from 'react';
import './SharedStyles.css';

const PagTemos = () => {
  return (
    <div className="page-container">

      <h1>Termos e Condições</h1>
      <p>Ao aceder e usar nosso website, concorda com os seguintes Termos e Condições:      </p>

      <h2>Utilização do website:</h2>
      <ul>
        <li>Não deve usar nosso website para qualquer propósito ilegal ou não autorizado.
        </li>
        <li>Todo o conteúdo do website, incluindo textos, logos, imagens, é propriedade exclusiva da Conta&Meia.</li>
        <li>Não somos responsáveis por qualquer dano direto, indireto, acidental, ou consequente resultante do uso ou incapacidade de uso do nosso website.</li>
      </ul>

      <h2>Compras e Pagamentos</h2>
      <ul>
        <li>Todas as compras estão sujeitas à disponibilidade dos produtos.
        </li>
        <li>Reservamos o direito de recusar qualquer pedido, apoiado em justa causa.
        </li>
        <li>Os preços estão sujeitos a alterações sem aviso prévio, contudo não se aplicam aos valores das encomendas já realizadas e por enviar.</li>
        <li>Aceitamos os seguintes métodos de pagamento: transferência bancária, MBWay, Revolut e PayPal.
        </li>
        <li>As encomendas serão enviadas após confirmação do pagamento por parte da Conta&Meia. É obrigatório que o cliente envie um comprovativo de pagamento, aquando da realização da encomenda.</li>
        <li>O não envio do comprovativo de pagamento no ato de compra não permite o envio da encomenda.</li>
        <li>O comprovativo de pagamento deve conter o valor total da encomenda, este valor inclui o custo dos produtos selecionados e o custo do método de envio selecionado pelo cliente. Caso o valor expresso no comprovativo de pagamento não estiver de acordo com o supramencionado o cliente está sujeito a dois cenários:
        </li>
        <ul>
        <li>Caso o valor enviado no ato de pagamento da encomenda for inferior ao valor encomendado, entraremos em contacto no prazo de 48h para retificar a situação e requisitar o valor restante.  </li>
        <li>Caso o valor enviado no ato de pagamento da encomenda for superior ao valor encomendado, entraremos em contacto no prazo de 48h para retificar a situação e fazer o reembolso do excedente.  </li>
        </ul>
      </ul>
      <h2>Envio e Entrega:</h2>

      <ul>
      <li>As encomendas estão sujeitas a custos de envio, com base no método de envio escolhido pelo cliente.</li>
        <li>O cliente pode optar por envio com ou sem tracking. </li>
        <ul>
        <li>Envio com Tracking: Inclui um número de rastreamento que permite acompanhar o estado da entrega.  </li>
        <li>Envio sem Tracking: Não inclui rastreamento e, portanto, não podemos ser responsabilizados por perdas ou danos ocorridos durante o transporte. </li>
        </ul>
        <li>O prazo estimado de entrega varia consoante o destino e o método de envio escolhido.</li>
        <li>Não nos responsabilizamos por atrasos nas entregas causados por motivos alheios ao nosso controlo, como greves, condições meteorológicas adversas, ou outros eventos de força maior. </li>
      </ul>

      <p>Estes termos estão sujeitos a alterações periódicas. A data da última atualização estará disponível no topo. </p>
      <p>Para dúvidas e esclarecimentos, entre em contato conosco através de help.contaemeia@gmail.com. </p>



</div>
  );
};

export default PagTemos;
