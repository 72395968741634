// src/pages/admin/AdminDashboard.js
import React from 'react';
import './AdminDashboard.css';
import { Helmet } from 'react-helmet';


function AdminDashboard() {
  return (
    <div className="admin-dashboard">
        <Helmet>
        <title>Admin</title>
        <meta name="description" content="Página de administração" />
           <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
      </Helmet>
      <h1>Dashboard</h1>
      <div className="stats">
        <div>
          <h2>Bem vindo de volta</h2>
          <p>Ao melhor empreendimento do pais</p>
        </div>

      </div>
      {/* Add more dashboard content here */}
    </div>
  );
}

export default AdminDashboard;
