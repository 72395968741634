import React, { useState, useEffect } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const testimonials = [
  {
    quote: "acabei de abrir a minha caixa de correio e adorei: que rapidez, que lindo! Amei o fio😍",
  },
  {
    quote: "@conta.e.mela,, tem peças lindissimas!! obrigada😻😻",
  },
  {
    quote: "Sigam mt a página, tem coisas lindas e preços Acessíveis!",
  },
  {
    quote: "Mais uma vez super satisfeita! Muito obrigada, fica super bem, adoro🥰",
  },
  {
    quote: "Obrigada !!!Vou encomendar mais coisas sem dúvida!!!",
  },

];

function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const getTestimonialIndex = (index) => {
    return (index + testimonials.length) % testimonials.length;
  };

  const handleSwipe = (direction) => {
    if (direction === 'left') {
      nextTestimonial();
    } else if (direction === 'right') {
      prevTestimonial();
    }
  };

  let touchStartX = 0;

  const onTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const onTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    if (touchStartX - touchEndX > 50) {
      handleSwipe('left');
    } else if (touchStartX - touchEndX < -50) {
      handleSwipe('right');
    }
  };
  return (
    <div className="home">
      <Helmet>
        <title>Vê já a nossa nova coleção!!</title>
        <meta name="description" content="Explora todas as peças da nova coleção da Conta&Meia, cada peça, um pedaço da tua história." />
        <meta name="keywords" content="jewelry, handmade, unique jewelry, Conta&Meia, personal jewelry, stories , contaemeia, bijutaria, página inicial, pulseiras , brincos, aneis, colares" />
        <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
        <meta property="og:description" content="Discover handcrafted jewelry pieces in our New Beginnings collection." />
        <meta property="og:image" content="https://https://contaemeia.com/favicon.ico" />
      </Helmet>
      <section className="hero">
        <div className="hero-content">
          <h1>Coleção: New Beginnings     <br></br>Já Disponível!</h1>
          <p>Espreita todos os nossos produtos, entre fios, pulseiras, aneis e brincos, escolhe os teus favoritos.</p>
          <p><strong>Cada Peça, um Pedaço da tua História.</strong></p>
          <button type="button" onClick={() => navigate('/shop')}>Ver Coleção</button>
          <div className="frame">
            <div className="div">
              <div className="text-wrapper">Aço Inoxidável</div>
              <div className="text-wrapper-2">Peças disponiveis</div>
            </div>
            <svg className="imgn" xmlns="http://www.w3.org/2000/svg" width="6" height="64" viewBox="0 0 16 16">
              <path fill="currentColor" d="M8 0h0.5v16H8z" opacity="0.5" />
            </svg>
            <div className="div">
              <div className="text-wrapper">9.8/10</div>
              <div className="text-wrapper-2">Satistação dos Clientes</div>
            </div>
            <svg className="imgn" xmlns="http://www.w3.org/2000/svg" width="6" height="64" viewBox="0 0 16 16">
              <path fill="currentColor" d="M8 0h0.5v16H8z" opacity="0.5" />
            </svg>
          </div>
        </div>
        <div className="hero-image">
          <picture>
            <source media="(max-width: 768px)" srcSet="/images/hero-image-mobile.png" />
            <source media="(min-width: 769px)" srcSet="/images/hero-image.png" />
            <img src="/images/hero-image.png" alt="Hero" />
          </picture>
        </div>
      </section>
      <div className="free-shipping-banner">
        <p>Portes grátis a partir de 30€</p>
      </div>
      <section className="sustainability">
        <div className="sustainability-container">
          <div className="sustainability-text">
            <h2>Sustentabilidade</h2>
            <p>
            Na Conta&Meia trabalhamos arduamente para diminuir o nosso impacto no planeta e torná-lo num lugar melhor! Todas as nossas peças são produzidas de forma sustentável assim como o nosso packaging é totalmente reciclável e reutilizável! Empenhamos-nos a oferecer produtos versáteis e amigos do ambiente.            </p>
            <button type="button" onClick={() => navigate('/sustainability')}>Saber Mais</button>
          </div>
          <div className="sustainability-image">
            <img src="/images/sustentabilidade2.jpg" alt="Sustentabilidade" />
          </div>
        </div>
      </section>
      <section className="product-search">
        <div className="container2">
          <p className="text-wrapper">Os Nossos Produtos</p>
          <div className="overlap-group-wrapper">
            <div className="overlap-group" onClick={() => navigate('/shop')}>
              <img src="/images/rings2.jpg" alt="Anéis" />
              <div className="div">Anéis</div>
            </div>
          </div>
          <div className="overlap-wrapper" onClick={() => navigate('/shop')}>
            <div className="overlap">
              <img src="/images/bracelets.png" alt="Pulseiras" />
              <div className="text-wrapper-2">Pulseiras</div>
            </div>
          </div>
          <div className="div-wrapper" onClick={() => navigate('/shop')}>
            <div className="overlap-2">
              <img src="/images/fios.jpg" alt="Fios" />
              <div className="text-wrapper-3">Fios</div>
            </div>
          </div>
          <div className="overlap-wrapper-2" onClick={() => navigate('/shop')}>
            <div className="overlap-3">
              <img src="/images/brincos.jpg" alt="Brincos" />
              <div className="text-wrapper-4">Brincos</div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonials" onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
        <h2>Os Nossos Clientes Satisfeitos</h2>
        <div className="testimonial-carousel">
          <div
            className="carousel-track"
            style={{
              transform: `translateX(-${currentIndex * (20)}%)`,
            }}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`testimonial ${index === getTestimonialIndex(currentIndex) ? 'active' : ''}`}
              >
                <p>"{testimonial.quote}"</p>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
}

export default Home;
