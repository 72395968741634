import React from 'react';
import './SharedStyles.css';

const PagPrivacidade = () => {
  return (
    <div className="page-container">
      <h1>Política de Privacidade</h1>
      <p>A sua privacidade é muito importante para nós. Esta página explica como coletamos, usamos e protegemos as suas informações pessoais.</p>

    
<p>      A sua privacidade é importante para nós. Ao aceder e usar nosso website, tem total conhecimento da Política de Privacidade e Proteção de Dados em prática: 
</p>
<p> - Os dados pessoais recolhidos durante o processo de compra serão utilizados apenas para processar encomendas e melhorar a experiência do cliente.
</p>
<p>- Os dados dos clientes não serão partilhados com terceiros, exceto quando necessário para o cumprimento de obrigações legais ou para a execução do serviço (como transportadoras).
</p>
<p>- O cliente tem o direito de solicitar o acesso, correção ou eliminação dos seus dados pessoais em qualquer momento, contactando-nos através do nosso e-mail de contacto.
</p>
<p>A política de Privacidade está sujeita a atualizações periódicas. A data da última atualização está supramencionada.
</p>
<p>Para dúvidas e esclarecimentos, entre em contato conosco através de help.contaemeia@gmail.com.
</p>



     </div>
  );
};

export default PagPrivacidade;
