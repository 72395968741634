import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { useCart } from '../contexts/CartContext';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const Header = () => {
  const { cart } = useCart();
  const [collections, setCollections] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const fetchCollections = async () => {
      const collectionSnapshot = await getDocs(collection(db, 'collections'));
      const collectionsList = collectionSnapshot.docs.map(doc => doc.data());
      setCollections(collectionsList);
    };

    fetchCollections();
  }, []);

  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="container header-content">
        <div className="header-logo">
          <Link to="/">
            <img src="/images/logo.png" alt="Corta&Meia" />
          </Link>
        </div>

        <div className={`header-nav ${menuOpen ? 'show' : ''}`}>
        <div className="close-button" onClick={() => setMenuOpen(false)}>
  &times; {/* HTML code for the "X" symbol */}
</div>

          <Link to="/shop" onClick={() => setMenuOpen(false)}>Produtos</Link>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={toggleDropdown}>
              Coleções
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu">
                {collections.map((collection, index) => (
                  <Link
                    key={index}
                    to={`/shop`}
                    className="dropdown-item"
                    onClick={() => setMenuOpen(false)}
                  >
                    {collection.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <Link to="/sustainability" onClick={() => setMenuOpen(false)}>Sustentabilidade</Link>
          <Link to="/about-us" onClick={() => setMenuOpen(false)}>Sobre Nós</Link>
          <Link to="/contact" onClick={() => setMenuOpen(false)}>Contactos</Link>
        </div>

        <div className="header-actions">
          <div className="header-cart">
            <Link to="/cart">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <g fill="none">
                  <circle cx="7.5" cy="18.5" r="1.5" fill="currentColor" />
                  <circle cx="16.5" cy="18.5" r="1.5" fill="currentColor" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5h2l.6 3m0 0L7 15h10l2-7z" />
                </g>
              </svg>
              {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
            </Link>
          </div>

          <div className="hamburger-menu" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
