import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const ContactDetail = () => {
  const location = useLocation();
  const { contact } = location.state || {}; // Access the contact data from the state

  if (!contact) {
    return <p>No contact details found.</p>;
  }

  return (
    <div>
            <Helmet>
        <title>Admin</title>
        <meta name="description" content="Página de administração" />
           <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
      </Helmet>
      <h1>Detalhes do Contacto</h1>
      <p>Nome: {contact.name}</p>
      <p>Email: {contact.email}</p>
      <p>Telefone: {contact.phone}</p>
      <p>Assunto: {contact.subject}</p>
      <p>Mensagem: {contact.message}</p>
      <p>Data: {contact.date}</p>
      <p>Hora: {contact.time}</p>
    </div>
  );
};

export default ContactDetail;
