import React from 'react';
import './ThankYou.css';

const ThankYou = () => {
  return (
    <div className="thank-you">
      <h1>Obrigada por realizares a tua encomenda Conta&Meia</h1>
      <div className="thank-you-message">
        <p>Assim que for confirmada a receção do pagamento proceguiremos ao envio dos produtos!</p>
        <p>Esteja atento(a) ao seu e-mail para mais informações!</p>
        <hr className="divider" />
        <p> Caso surja alguma dúvida, não hesites em contactar-nos através das nossas redes sociais ou do e-mail   <strong>help.contaemeia@gmail.com</strong></p>
      </div>
    </div>
  );
};

export default ThankYou;
