import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Adjust import based on your file structure
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContacts = async () => {
      const q = query(collection(db, 'contacto'), orderBy('timestamp', 'desc')); // Order by timestamp (most recent first)
      const querySnapshot = await getDocs(q);
      const contactsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setContacts(contactsList);
    };

    fetchContacts();
  }, []);

  const handleDetailsClick = (contact) => {
    navigate(`/c0n1aemeia-vamosficarricos-admin/contactdetail/${contact.id}`, { state: { contact } });
  };

  return (
    <div>
            <Helmet>
        <title>Admin</title>
        <meta name="description" content="Página de administração" />
           <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
      </Helmet>
      <h1>Lista de Contactos</h1>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Hora</th>
            <th>Nome</th>
            <th>Assunto</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr key={contact.id}>
              <td>{contact.date}</td>
              <td>{contact.time}</td>
              <td>{contact.name}</td>
              <td>{contact.subject}</td>
              <td>
                <button onClick={() => handleDetailsClick(contact)}>Ver Detalhes</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactList;
