import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db, functions } from '../../firebase'; // Correctly import db and functions from your Firebase config
import { doc, updateDoc, getDoc } from 'firebase/firestore'; // Import Firestore methods
import { httpsCallable } from 'firebase/functions'; // Import callable functions from Firebase
import './OrderDetails.css';
import { Helmet } from 'react-helmet';


const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { order } = location.state;
  const [status, setStatus] = useState(order.status);
  const [products, setProducts] = useState([]);
  const [emailSending, setEmailSending] = useState(false); // State to handle email sending
  const [trackingCode, setTrackingCode] = useState(''); // State for tracking code input

  useEffect(() => {
    const fetchProducts = async () => {
      const productDetails = await Promise.all(
        order.cart.map(async (item) => {
          const productRef = doc(db, 'products', item.id);
          const productSnap = await getDoc(productRef);
          return { ...item, sku: productSnap.data()?.sku };
        })
      );
      setProducts(productDetails);
    };

    fetchProducts();
  }, [order.cart]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSaveStatus = async () => {
    const orderRef = doc(db, 'orders', order.id);
    try {
      await updateDoc(orderRef, { status }); // Update the status
      alert('Status updated successfully!');

      // Navigate back to the orders page
      navigate('/c0n1aemeia-vamosficarricos-admin/orders');
    } catch (error) {
      console.error('Error updating status: ', error);
    }
  };

  const sendEmail = async (emailData) => {
    setEmailSending(true);
    
    try {
      const response = await fetch('https://us-central1-contameia.cloudfunctions.net/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });
  
      const result = await response.json();
      if (response.ok) {
        console.log('Email sent successfully:', result);
        alert('Email sent successfully!');
      } else {
        console.error('Error sending email:', result.error);
        alert('Failed to send email.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    } finally {
      setEmailSending(false);
    }
  };
  


  // First email - Payment Confirmation
 // First email - Payment Confirmation
const handlePaymentConfirmation = async () => {
  // Log the email data for debugging
  console.log('Sending email to:', order.email);
  console.log('Order details:', order.firstName, order.orderNumber);

  const emailData = {
    personalizations: [
      {
        to: [
          {
            email: order.email,  // Recipient's email
          }
        ],
        dynamic_template_data: {
          nome: order.firstName,  // Dynamic data used in your template
          numero: order.orderNumber,
        }
      }
    ],
    from: {
      email: 'conta.e.meia@gmail.com',  // Must be a verified sender email in SendGrid
    },
    template_id: "d-2c99f207d7fe4e53bfeeb414837fdeb3",  // Your dynamic template ID
  };

  // Debugging: Log the full email data to ensure it's correctly formed
  console.log('Email data being sent:', emailData);

  try {
    const response = await sendEmail(emailData);  // Await the sendEmail function
    if (response && response.status === 202) {  // SendGrid returns 202 for success
      console.log('Email sent successfully:', response);
      alert('Email sent successfully!');
    } else {
      console.error('Unexpected response from SendGrid:', response);
      alert('Failed to send email. Please check the logs.');
    }
  } catch (error) {
    console.error('Error sending email:', error.response ? error.response.body : error);
    alert('Failed to send email. Please check the logs.');
  }
};

  
  
  const handleSendShippingEmail = async () => {
    console.log('Sending email to:', order.email);
    console.log('Order details:', order.firstName, order.orderNumber);
  
    const emailData = {
      personalizations: [
        {
          to: [
            {
              email: order.email,  // Recipient's email
            }
          ],
          dynamic_template_data: {
            nome: order.firstName,  // Dynamic data used in your template
            numero: order.orderNumber,
            rastreio: trackingCode,
          }
        }
      ],
      from: {
        email: 'conta.e.meia@gmail.com',  // Must be a verified sender email in SendGrid
      },
      template_id: "d-af872d4c534f433bb44d0b0f428e4181",  // Your dynamic template ID
    };
  
    console.log('Email data being sent:', emailData);
  
    try {
      const response = await sendEmail(emailData);
      if (response && response.status === 202) {
        console.log('Email sent successfully:', response);
        alert('Email sent successfully!');
      } else {
        console.error('Unexpected response from SendGrid:', response);
        alert('Failed to send email. Please check the logs.');
      }
    } catch (error) {
      console.error('Error sending email:', error.response ? error.response.body : error);
      alert('Failed to send email. Please check the logs.');
    }
  };
  

  const handleRequestEvaluation = () => {
    console.log('Sending email to:', order.email);
    console.log('Order details:', order.firstName, order.orderNumber);
   
    const emailData = {
      personalizations: [
        {
          to: [
            {
              email: order.email,  // Recipient's email
            }
          ],
          dynamic_template_data: {
            nome: order.firstName,  // Dynamic data used in your template
          }
        }
      ],
      from: {
        email: 'conta.e.meia@gmail.com',  // Must be a verified sender email in SendGrid
      },
      template_id: "d-392eb9d3ed43483fb312ef6486be98de",  // Your dynamic template ID
    };
   
    // Debugging: Log the full email data to ensure it's correctly formed
    console.log('Email data being sent:', emailData);
   
    sendEmail(emailData)
      .then(response => {
        // Check if the response is successful
        if (response && response.status === 202) {  // SendGrid returns 202 for success
          console.log('Email sent successfully:', response);
          alert('Email sent successfully!');
        } else {
          // If the response is not 202, log the details and show an error
          console.error('Unexpected response from SendGrid:', response);
          alert('Failed to send email. Please check the logs.');
        }
      })
      .catch(error => {
        console.error('Error sending email:', error.response ? error.response.body : error);
        alert('Failed to send email. Please check the logs.');
      });
  };
  

  return (
    <div className="order-details">
            <Helmet>
        <title>Admin</title>
        <meta name="description" content="Página de administração" />
           <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
      </Helmet>
      <h1>Encomenda Nº {order.orderNumber}</h1>
      <div className="order-info">
        <div className="left-column">
          <p><strong>Endereço de Email:</strong> {order.email}</p>
          <p><strong>Nome:</strong> {order.firstName} {order.lastName}</p>
          <p><strong>Data:</strong> {order.timestamp ? new Date(order.timestamp.seconds * 1000).toLocaleString() : 'N/A'}</p>
          <p><strong>País:</strong> {order.country}</p>
          <p><strong>Morada:</strong> {order.address}</p>
          <p><strong>Localidade:</strong> {order.city}</p>
          <p><strong>Código Postal:</strong> {order.postalCode}</p>
          <p><strong>Telefone:</strong> {order.phone}</p>
          <p><strong>Forma de Pagamento:</strong> {order.paymentMethod}</p>
          <p><strong>Preço Total:</strong> {order.total}€</p>
          <p><strong>Tipo de Envio:</strong> {order.shippingMethod}</p>
          <p><strong>Preço do Envio:</strong> {order.shippingCost}€</p>

          <div className="status-update">
            <label htmlFor="status">Estado da Encomenda:</label>
            <select id="status" value={status} onChange={handleStatusChange}>
              <option value="Confirmar Pagamento">Confirmar Pagamento</option>
              <option value="Enviar">Enviar</option>
              <option value="Enviado">Enviado</option>
              <option value="Concluido">Concluido</option>
              <option value="Encerrada">Encerrada</option>
            </select>
            <button onClick={handleSaveStatus} disabled={emailSending}>
              {emailSending ? "Salvando e Enviando..." : "Guardar Estado"}
            </button>
          </div>

          {/* First Button - Payment Confirmation */}
          <button onClick={handlePaymentConfirmation} disabled={emailSending}>
            {emailSending ? "Enviando Confirmação..." : "Confirmar Pagamento"}
          </button>

          {/* Second Button - Shipping Email with Tracking Code */}
          <div>
            <input
              type="text"
              value={trackingCode}
              onChange={(e) => setTrackingCode(e.target.value)}
              placeholder="Código de Rastreamento"
            />
            <button onClick={handleSendShippingEmail} disabled={emailSending || !trackingCode}>
              {emailSending ? "Enviando Email de Envio..." : "Enviar Código de Envio"}
            </button>
          </div>

          {/* Third Button - Request Evaluation */}
          <button onClick={handleRequestEvaluation} disabled={emailSending}>
            {emailSending ? "Enviando Avaliação..." : "Solicitar Avaliação"}
          </button>
        </div>

        <div className="right-column">
          <h2>Encomenda</h2>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>SKU</th>
                <th>Quantidade</th>
              </tr>
            </thead>
            <tbody>
              {products.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.sku}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="proof-container">
            <label><strong>Comprovativo de Pagamento:</strong></label>
            {order.proofURL ? (
              <a href={order.proofURL} target="_blank" rel="noopener noreferrer">Ver Comprovativo</a>
            ) : (
              <p>Nenhum comprovativo enviado</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
