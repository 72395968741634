import React from 'react';
import './SharedStyles.css';

const PagEnvios = () => {
  return (
    <div className="page-container">
      <h1>Política de Envios</h1>
      <p>Aqui encontras todas as informações sobre o nosso processo de envio, prazos de entrega, e outras informações.</p>

      <h2>Prazos de Entrega</h2>
      <p>O prazo de entrega das encomendas varia conforme a localização. Em média, as entregas nacionais demoram entre 3 a 5 dias úteis. Para entregas internacionais, o prazo pode ser de 7 a 15 dias úteis. </p>

      <h2>Método de Envio</h2>
      <p>Oferecemos 2 opções de envio:</p>
      <ul>
        <li>Correio Normal (3€ por encomenda) - não existe possibilidade de rastreio da tua encomenda e a Conta&Meia não é responsável caso haja alguma perda </li>
        <li>Correio Registado (5€ por encomenda) - fornecemos o número de rastreio para que possas acompanhar a tua encomenda até chegar a tua casa!.</li>
      </ul>
      
 </div>
  );
};

export default PagEnvios;
