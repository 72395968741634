import React, { useState } from 'react';
import './FAQ.css';
import { Helmet } from 'react-helmet';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: '1. O que torna a bijuteria de aço inoxidável especial?',
      answer: 'Ao contrário de outros metais, o aço inoxidável não enferruja, mancha ou desbota. A Conta&Meia é uma marca de bijuteria de aço inoxidável, pelo que todos os produtos têm as seguintes caracteristicas: resistência à corrosão e desgaste; amigável para peles sensíveis (hipoalergénico); brilho duradouro; fácil limpeza.'
    },
    {
      question: '2. Como devo cuidar das minhas peças de aço inoxidável?',
      answer: 'Cuidar das peças de aço inoxidável é simples. Recomendamos limpá-las após o uso com um pano macio e seco, para remover resíduos de suor ou produtos químicos. Caso o pano seco não seja suficiente, podes/deves usar água morna e sabão neutro. Evita produtos de limpeza abrasivos e guarda as tuas peças num local seguro para evitarem arranhões.'
    },
    {
      question: '3. As peças de aço inoxidável podem causar alergias?',
      answer: 'O aço inoxidável é geralmente hipoalergénico e é menos provável de causar reações alérgicas em comparação com outros metais. No entanto, cada pessoa é única, desta forma recomendamos testar o uso de uma peça antes de adquirir várias.'
    },
    {
      question: '4. Como posso saber o tamanho correto para os anéis/pulseiras/colares?',
      answer: 'As peças Conta&Meia têm tamanhos standard especificados na descrição das mesmas, para além disso todos os fios e pulseiras são compostos por um extensor que permite o ajuste ao gosto de cada um. Os colares podem ter 40, 45, 50 cm de comprimento (abertos), as pulseiras são ajustáveis ou têm entre 18 a 20 cm de comprimento (abertas). O anéis Conta&Meia são ajustáveis, assim adaptam-se ao dedo de cada cliente!'
    },
    {
      question: '5. Qual é o prazo de entrega das encomendas?',
      answer: 'O prazo de entrega das encomendas varia conforme a localização. Em média, as entregas nacionais demoram entre 3 a 5 dias úteis. Para entregas internacionais, o prazo pode ser de 7 a 15 dias úteis. Após a confirmação do envio, caso tenhas requisitado a tua encomenda através de correio registado, forneceremos o número de rastreio para que possas acompanhar a tua encomenda até chegar a tua casa!'
    },
    {
      question: '6. Posso devolver ou trocar uma peça?',
      answer: 'Todas as informações sobre trocas e devolução encontram-se nos nossos Termos e Condições e Política de Devoluções e Reembolso. Aconselhamos que dês uma vista de olhos!'
    },
    {
      question: '7. Como posso acompanhar a minha encomenda?',
      answer: 'Após o envio da tua encomenda, caso tenhas requisitado envio por correio registado, receberás um e-mail com o número de rastreio e um link para acompanhar o status da entrega. Caso não recebas este e-mail, verifique a pasta de spam ou entra em contacto connosco através das nossas redes sociais ou do e-mail help.contaemeia@gmail.com.'
    },
    {
      question: '8. Oferecem personalização nas peças?',
      answer: 'Atualmente, não oferecemos personalização nas nossas peças. No entanto, estamos sempre a trabalhar para expandir os nossos serviços e temos a certeza que num futuro próximo poderemos oferecer essa opção! Está atenta/o às nossas redes sociais e website!'
    },
    {
      question: '9. Como posso contactar o serviço de apoio ao cliente?',
      answer: 'Podes contactar-nos através das nossas redes sociais ou pelo e-mail help.contaemeia@gmail.com. A Equipa Conta&Meia está sempre disponível para ajudar, respondemos o mais brevemente possível.'
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
            <Helmet>
        <title>Tem alguma questão? veja as nossas questões frequentemente colocadas</title>
        <meta name="description" content="Vizualiza algumas das questões que nos são colocadas frequentemente, se continuar com questões, não hesite em nos contactar" />
        <meta name="keywords" content="jewelry, handmade, unique jewelry, Conta&Meia, FAQ, questões , contaemeia, bijutaria, página inicial, pulseiras , brincos, aneis, colares" />
        <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
        <meta property="og:description" content="Discover handcrafted jewelry pieces in our New Beginnings collection." />
        <meta property="og:image" content="https://https://contaemeia.com/favicon.ico" />
      </Helmet>
      <h1 className="faq-title">Perguntas Frequentes</h1>
      {faqs.map((faq, index) => (
        <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`} onClick={() => handleToggle(index)}>
          <div className="faq-question">
            {faq.question}
            <span className="arrow">{activeIndex === index ? '▲' : '▼'}</span>
          </div>
          {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
